@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Avenir", sans-serif;
}

.JsonEditor {
  background-color: rgb(60, 61, 72);
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.page-edit .ql-container.ql-snow {
  min-height: 600px;
}

.page-edit .ql-container.ql-snow .ql-editor {
  min-height: calc(600px - 2rem);
}

.ql-container.ql-snow .ql-editor {
  min-height: calc(300px - 2rem);
}

.ql-editor {
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 100%;
}

.ql-toolbar.ql-snow {
  border-width: 1px;
  background-color: rgb(237, 242, 247);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding: 13px;
  color: white;
  border-color: transparent;
}

.ql-container.ql-snow {
  min-height: 300px;
  padding: 1rem;
  color: white;
}

.ql-container.ql-snow {
  background-color: transparent;
  border-color: transparent;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  background-color: rgba(60, 61, 72, 1);
}

div#font-picker {
  width: 100%;
  box-shadow: none;
}
div#font-picker button.dropdown-button {
  background-color: transparent;
  background: transparent;
  padding: 4px;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: rgba(210, 214, 220, var(--border-opacity));
  height: 50px;
}

[hidden] {
  display: none;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px;
}

/* Hide default HTML checkbox */
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8a8a8a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0ca361;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0ca361;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.max-w-xs1 {
  max-width: 13rem;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #9c9c9c;
  border-radius: 10px;
}

::-webkit-scrollbar-button:end:increment {
  width: 2.6rem;
  display: block;
  background: transparent;
}

.project-edit-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.project-edit-sidebar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* ::-webkit-scrollbar-track-piece {
  background: white;
  border: solid 1px black;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}

.max-h-124 {
  max-height: 26rem;
}

.h-124 {
  height: 26rem;
}
